<template>
  <div class="customer-service">
    <header class="page-header">
      <van-icon name="arrow-left" class="theme-color" @click="$router.go(-1)" />
      <span>问题反馈</span>
      <span class="theme-color f-14" @click="onSubmit">
        <van-icon name="guide-o" class="icon-primary" /> 发布</span
      >
    </header>
    <main class="page-body">
      <van-field
        class="mop"
        type="textarea"
        v-model="text"
        placeholder="请详细描述你的问题和意见……"
      ></van-field>
      <div class="card">
        您的信息客服将会在工作日24小时内回复，您如果有紧急的事情需要处理，请直接电话联系
      </div>
    </main>
  </div>
</template>
<script>
export default {
  data() {
    return {
      text: '',
    };
  },
  methods: {
    onSubmit() {},
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.van-field {
  margin: rem(20px);
}
.card {
  background: #fff;
  padding: rem(20px);
  color: #b8b8b8;
}

.theme-color {
  float: right;
  width: 50px;
  position: relative;
}

.page-header {
  padding: 0;
}
.page-body {
  width: 100%;
  .mop {
    width: 95%;
  }
}
</style>
